.exptitle {
    margin-bottom: 1.5rem;
}

.expdiv {
    width: 100%;
    display: grid;
    /* grid-template-columns: repeat(3,1fr); */
    gap: 2rem;
}

.expset {
    background: rgba(0, 0, 0, 0.25);
    padding: 1.3rem;
    border-radius: 2rem;
    color: white;
}

.expset h2 {
    color: #f89c1e;
    margin-bottom: 0.5rem;
    padding-left: 2rem;
}

.skillset {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    padding-left: 2rem;

}

.article{
    padding: 0.5rem;
    border-radius: 1rem;
}
.skillimg{
    padding: 1.5rem;
}
.skillset h4 {
    color: #02807d;
    text-align: center;
}

.skillset small{
    color: var(--color-light);
    text-align: center;
    display: block;
}
.article:hover{
    
    box-shadow: 2px 2px 5px 5px #545454;
}
.article:hover .skillimg{
    filter:grayscale(100%);
}


/*for devices*/
@media screen and (max-width:1024px) {}

@media screen and (max-width:600px) {
    .skillset {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: left;
    }

    section>h2 {
        margin-top: 2rem;
    }

}
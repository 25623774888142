header{
    /* background: var(--color-primary); */
    padding-bottom: 4rem;
    
}
.header_container{
    height: 100vh;
    display: flex;
    width: 100%;
    justify-content: center;
}
.header_content{
    color: var(--color-light);
    margin: auto;
}
.header_content p{
    font-size: 2rem;
    margin-bottom: 1rem;
}
.name{
    color: #f89c1e;
    font: 6rem sans-serif;
    padding: 1rem;
}
.header_content h4{
    margin-bottom: 0.2rem;
}
.hire{
    margin-top: 2rem;
    
}
.hire a{
    padding: 1rem;
    border-radius: 0.5rem;
    color: white;
    background: #02807d;
}
.hire a:hover{
    background: transparent;
    border: 1px solid #02907d;
    
}
.headericon{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    position: absolute;
    left: 6rem;
    bottom: 10rem;
}
.headericon a{
    color: #02807d;
    
}
.headericon a:hover{
    color: #f89c1e;
}
.headerswipe{
    position: absolute;
    right: 5rem;
    bottom: 15rem;
    transform: rotate(90deg);
}
.headerswipe a{
    color: #fd7e14;
}
.headerswipe a:hover{
    color: #02807d;
}



/*media queries*/
@media screen and (max-width:1024px) {
    .header_container{
        width: 70%;
    }
 }
 
 @media screen and (max-width:600px) {
    .container .header_container{
        width: 90%;
        justify-content: center;
    }
    .header_content{
        margin-left: 3.5rem;
        margin-top: auto;
    }
    .name{
        font-size: 5rem;
    }
    .headericon{
        left: 1rem;
        bottom: 14rem;
    }
    .headerswipe{
        display: none;
    }
    
    
 }
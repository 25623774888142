.container.testi_container{
    width: 50%;
}
.client_avatar{
    width: 6rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}
.testimonial{
    background: var(--color-bg-variant) ;
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}
.client_review{
    color: var(--color-light);
    font-weight: 300;
    display: block;
    /* width: 90%; */
    margin: 0.8rem 2rem 1rem ;
    text-align: justify;
}


/*media queries*/
@media screen and (max-width:1024px) {
    .container.testi_container{
        width: 60%;

    }
    
 }
 
 @media screen and (max-width:600px) {
    .container.testi_container{
        width: var(--container-width-sm);
    }
    .client_review{
        width: var(--container-width-sm);

    }
    
 }
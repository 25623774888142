
.imggroup{
    background: white;
    display: flex;
    flex-direction: row;
    gap:3rem;
    padding: 2rem;
    border-radius: 1rem;
}
.img img{
    border-radius: 5px;
}
.img:hover{
    box-shadow: 5px 10px 10px #888888;
}


@media screen and (max-width:600px) {
    .imggroup{
        display: grid;
        gap: 1rem;
        background: transparent;
    }
    .img img{
        border-radius: 1rem;
    }
 }
.portfolio_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio_item {
    position: relative;
    text-align: center;
    color: white;
    background: linear-gradient(#f89c1e, #303f9f);
}

.portfolio_item-img {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.portfolio_container-cta{
    opacity: 0;
    position: absolute;
    left: 1rem;
    top: 1rem;
    gap: 1rem;
}

.portfolio_item:hover .portfolio_item-img {
    opacity: 0.1;
}

.portfolio_item:hover .portfolio_container-cta{
    opacity: 1;
}
.btn-primary1{
    color: white !important;
    background: #0d6efd;
}
.btn-primary1:hover{
    background: #f89c1e !important;
    color: #0d6efd !important;
}

@media screen and (max-width:600px) {
    .portfolio_container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    
 }
.container.contact_container{
    width: 70%;
    display: grid;
    grid-template-columns: 25% 58%;
    gap: 20%;
}
.contact_options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.contact_option{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}
.contact_option:hover{
    background: transparent;
    border-color: var(--color-primary-variant); 
}
.contact_option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
.iconset{
    display: inline-flex;
    gap: 0.5rem;
}
.contact_option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}
form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
input,textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: white;
}

/*media queries*/
@media screen and (max-width:1024px) {
    .container.contact_container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    
 }
 
 @media screen and (max-width:600px) {
    .container.contact_container{
        width: var(--container-width-sm);
    }
    .contact_options{
        margin: 0 4rem 0;
    }
    form{
        margin: 0 3rem 0;
    }
    
 }